<script>
import NextMixinListPage from "@/components/mixins/NextMixinListPage";
import MixinActions from "@/components/mixins/MixinListActions";
import AppMenu from "../menu/AppMenu.vue";
import MixinList from "./MixinList";
import * as templateDesktop from "./CreditNoteListAnnulledDesktop.vue?vue&type=template";
import * as templateMobile from "./CreditNoteListAnnulledMobile.vue?vue&type=template";
import ModalShow from "./ModalShow.vue";
let render = templateDesktop.render;
if (window.innerWidth < 960) {
  render = templateMobile.render;
}

export default {
  mixins: [NextMixinListPage, MixinActions,MixinList],
  components: { ModalShow, AppMenu },
  render: render,
  data() {
    return {
      title: "Notas Crédito Anulados",
      form: {},
      url: "api/v1/de-credit-note-annulled",
      pathBase: "/pos/de-credit-note",
      pathCreate: "/pos/de-credit-note/create",
      options: {
        page: 1,
        itemsPerPage: 10,
        itemsPerPageOptions: [10, 25, 50, 100],
        sortBy: ["created_at"],
        sortDesc: [false],
      },

      headers: [
        { title: "num", value: "index", sortable: false },
        {
          title: "credit_note_date",
          value: "credit_note_date",
          sortable: true,
        },
        {
          title: "consecutive_credit_note",
          value: "consecutive_credit_note",
          sortable: true,
        },
        { title: "invoice", value: "invoice", sortable: false },
        { title: "is_validate", value: "is_validate", sortable: true },
        { title: "status_dian", value: "status_dian", sortable: true },
        { title: "total_note", value: "total_note", sortable: true },
        { title: "_actions", value: "_actions", sortable: false },
      ],
    };
  },
  methods: {
    breadcrumbsInit() {
      this.breadcrumbs = [];
    },
  },
};
</script>
